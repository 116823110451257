.card {
    border: 1px solid #ccc;
    border-radius: 0 48px 0 48px;
    padding: 20px;
    margin: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    cursor: default;
}

.card-icon {
    font-size: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-icon span{
    border-radius: 16px;
    padding: 2px 15px;
    background-color: #615c5c44;
}
.card:hover span{
    background-color: var(--list-active-color);
    color: var(--sl-color-black);
} 
.card-content h3 {
    font-size: 18px;
    margin: 10px 0;
}

.card-content p {
    font-size: 16px;
}

.card:hover {
    transform: translateY(-5px);
}
@media screen and (max-width: 1024px) {
      
    .card {
      width: 250px;
      margin: auto;
    }
  }
  

@media screen and (max-width: 780px) {
    .card {
        width: 220px;
        padding: 10px;
        cursor: not-allowed;
    }    
    .card-icon {
        font-size: 28px;
        margin-bottom: 10px;
        
    }
    .card:hover span{
        background-color: var(--sl-color-white);
        color: var(--sl-color-black);
    }
    .card-content h3 {
        font-size: 17px;
       
    }
    .card-content p {
        font-size: 15px;
    }
    .card:hover {
        transform: translateY(0px);
    }
}