.notfound__container {
    text-align: center;
    padding-top: 64px;
}
.redirect{
    width: 400px;
   margin: 0 auto;
}
.notfound__container h2{
    margin-bottom: 32px;
}
