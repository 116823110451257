*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: 0;
}

:root {
    --backdrop-color: rgba(39, 39, 39, 0.8);
    --sl-color-btn: #808080; /* Neutral gray for buttons */
    --sl-color-btn-mid: #5c5c5c;
    --sl-color-btn-low: #3a3a3a;
    --sl-color-black: #000000;
    --sl-color-gray-1: #f5f5f5;
    --sl-color-gray-2: #cccccc;
    --sl-color-gray-3: #999999;
    --sl-color-gray-4: #666666;
    --sl-color-gray-5: #333333;
    --sl-color-gray-6: #1a1a1a;
    --sl-color-white: #ffffff;
    --list-active-color: #b5b5b5; /* Subtle accent for active states */
}


/* :root {
    --backdrop-color: #272727cc;
    --sl-color-btn: #13c9aac4;
    --sl-color-btn-mid: #06573cb2;
    --sl-color-btn-low: #0531229d;
    --sl-color-black: #181818;
    --sl-color-gray-1: #eee;
    --sl-color-gray-2: #c2c2c2;
    --sl-color-gray-3: #8b8b8b;
    --sl-color-gray-4: #585858;
    --sl-color-gray-5: #383838;
    --sl-color-gray-6: #272727;
    --sl-color-white: #fff;
    --list-active-color: #b908c9b4;
    --conatiner-width-lg: 95%;
    --conatiner-width-md: 80%;
    --transition: all 500ms ease;
    --tw-bg-opacity: 1;
} */

body{
    font-family: "Montserrat", sans-serif;
    color: var(--sl-color-black);
    overflow-x: hidden;
    line-height: 1.7;
    background: var(--sl-color-white);
}
.app-container{
    max-width: 1440px;
    margin: 0 auto;
}

.container{
    transition: 0.2s;
    padding: 0 50px;
}
.app-container.light{
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
    color : var(--sl-color-black); 
}

button{
    border: none;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}
a {
    color: var(--sl-color-white);
}


/* Light mode styles */
.light nav {
    background: var(--sl-color-white);
    color: var(--sl-color-black);
}

.light .nav__links a {
    color: var(--sl-color-gray-6);
}
h1{
    color:var(--sl-color-black);
}

/* Dark mode styles */
body.dark {
    background: var(--sl-color-black);
    color: var(--sl-color-gray-2);
}
.dark nav {
    background: var(--sl-color-gray-6);
    color: var(--sl-color-gray-3);
    border-color: 0 solid #e5e7eb;
}

.dark .nav__links a {
    color: var(--sl-color-white);
}

.nav__links a:hover {
    color: var(--sl-color-gray-2);
    transition: var(--transition);
}

.active-link {
    color: var(--list-active-color) !important;
}

.toggle__icon {
    width: 15px;
    cursor: pointer;
    margin-right: 30px;
}
section{
    padding: 0 10px;
}
.dark h1{
    color: var(--sl-color-gray-1);
}
p{
    font-size: 18px;
    font-weight: 400;
    /* color: var(--sl-color-gray-1); */
}
.header{
    margin-top: 17px;
    height: 320px;
    overflow: hidden;
    border-bottom: 2px solid var(--sl-color-black);
    border-radius: 10px 10px 0 0;
}
.header__container{
    width: 100%;
    height: 100%;
    position: relative;
    display: grid;
    place-items: center;
}
.header__container-bg {
    position: absolute;
    width: 100%;
}
.header__container-bg img{
    opacity: 0.8;
    filter: brightness(50%) contrast(120%);
}
.header__content{
    position: relative;
    width: 30%;
    margin: 0 auto;
text-align: center;
}
.header__content h2{
margin-bottom: 16px;
color: var(--sl-color-gray-1);
}

.header__content p{
   font-weight: 600;
   color: var(--sl-color-gray-1);
}

.loading-text{
    font-size: 24px;
    font-weight: bold;
    width: 300px;
    margin: 60px auto;
}

/* MEDIA QUERIES */
@media screen and (width: 1024px){
    .header__content{
        width: 76%;
       
    }
    .header__content p {
        width: 400px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 780px) {

    h1{
        font-size: 30px;
    }
     h2{
        font-size: 20px;
     }
     p{
        font-size: 16px;
    }
     h3{
        font-size: 18px;
     }
     .header{
        height: 200px;
     }
     .header__content{
        width: 50%;
    }
}

/* MEDIA QUERIES */
@media screen and (max-width: 500px){
    h1{
        font-size: 25px;
        line-height: 1.3;
    }
    h2{
        font-size: 18px;
    }
    .header__content{
        width: 90%;
    }
    img {
        height: 100%;
    }

}
@media screen and (max-width: 480px) {
    .container{
        padding: 0;
    }
    
}