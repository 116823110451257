.platform-development{
    margin: 70px 0;
}
.text {
   width: 700px;
   margin: 0 auto;
    text-align: center;
}
.text p {
    width: 600px;
    margin: 10px auto;
    line-height: 1.6;
}
.key-features {
    width: 70%;
    margin: 60px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 130px;
    list-style-type: none;
}
.key-features li {
    width: 400px;
    margin: 10px 0;
} 
.key-features strong {
    display: block;
    margin-bottom: 5px;
} 
.visuals-demo {
    width: 500px;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
}
.visuals-demo h2{
    width: 160px;
    margin: 30px auto;
}
.screenshots {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.screenshots img {
    max-width: 200px;
    border: 1px solid #ddd;
    border-radius: 5px;
} 
.btn {
    background-color: var(--sl-color-btn);
    padding: 16px 32px;
    color: var(--sl-color-black);
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    transition: var(--transition);
}
.btn:hover{
background-color: var(--sl-color-btn-low);
color: var(--sl-color-gray-2);
}
@media screen and (max-width: 1024px){
    
    .key-features {
        width: 68%;
        margin: 0;    
    }
}
@media screen and (max-width: 800px){
    .key-features {
        width: 100%;
       gap: 60px;  
    }
    .key-features li {
        width: 300px;
    }
}
@media screen and (max-width: 650px){
    .text p {
        width: 346px;
    }
    .key-features {
        width: 300px;
        grid-template-columns: 1fr;
        gap: 0; 
        margin: 0 auto;  
    
    }
    .key-features li {
        width: 346px;
    }
    .visuals-demo h2{
        width: 100%;
       margin: 0 auto;
    }
}
@media screen and (max-width: 400px){
    .text p {
        width: 100%;
    }
    .key-features li {
        width: 100%;
    }
}