.gird__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 35px;
    gap: 60px;
    padding: 0 50px;
}
.about__story-text {
    margin-top: 0;
}
.about__story-images {
    width: 100%;
    display: flex;
}
.about__story-images .team__img{
    width: 350px;
    height: 300px;
    border-radius: 8px;
    margin-left: 20px;
}
.about__story-text .write-up{
    width: 100%;
}
.career__development{
    margin-top: 170px;
    text-align: center;
}
.career__development-text p{
    width: 500px;
    margin: 10px auto;
}
@media screen and (max-width: 1024px) {
    .about__story-images .team__img{
        width: 250px;
        height: 200px;
        gap: 0;
    }
    .gird__container {
        padding: 0;
    }
}
@media screen and (max-width: 900px) {
    .about__story-images .team__img{
        width: 180px;
        height: 130px;
    }
}
@media screen and (max-width: 600px) {
    .about__story-text .write-up{
        width: 100%;
    }
    .about__story-images {
        gap: 10px;
    }
     .career__development-text p{
        width: 320px;
        margin: 10px auto;
    }
}

@media screen and (max-width: 450px) {
    .about__story-images .team__img{
       margin: 60px 0 0;
    }

}
@media screen and (max-width: 400px) {
    .about__story-images .team__img{
        width: 150px;
        height: 135px;
        margin-top: 50px;
    }
    .about__story-text .write-up{
        width: 100%;
    }
    .career__development{
        margin-top: 0;
    }
    .career__development-text p{
        width: 100%;
       
    }

}