.about__story {
    margin-top: 20px;
}

.grid__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    margin-top: 55px;
}

.about__story-text {
    margin-top: 30px;
}

h2 {
    margin-top: 20px;
}

.image-story img {
    height: 400px;
    width: 100%; 
    object-fit: cover; 
}

.about__story-text p {
    width: 90%;
    margin-bottom: 20px;
}

.about__story-images {
    width: 100%;
    display: flex;
    margin-left: 60px;
    position: relative;
}

.about__story-images img {
    width: 50%;
    height: 350px;
    border-radius: 10px;
}

a.case-btn {
    display: block;
    width: 155px;
    margin-top: 30px;
    text-align: center; 
    text-decoration: none; 
    padding: 10px 15px; 
    border-radius: 5px; 
    transition: background-color 0.3s ease; 
}

a.case-btn:hover {
    background-color: #474849; 
}
.case-study-detail{
    padding: 0 60px;
}
.case-study-detail h1{
    text-align: center;
    margin: 70px 30px 20px;
}
hr {
    margin-left: 20px;
    width: 90%;
}
.case-study-detail p{
    margin: 10px; 
    width: 90%;  
}
.case-study-section{
    margin: 30px;
}
@media screen and (max-width: 1024px) {
    .grid__container { 
        gap: 30px;
    }
}

@media screen and (max-width: 780px) {
    .image-story img {
        height: 300px;
    }
}

@media screen and (max-width: 767px) {
    .grid__container { 
        grid-template-columns: 1fr;
    }
}
