nav {
    height: 60px;
    width: 100%;
    background: var(--sl-color-gray-6); /* This will be overwritten by theme classes */
    position: sticky;
    left: 0;
    top: 16px;
    z-index: 99;
    border: 1px solid var(--sl-color-gray-4);
    border-radius: 20px;
}
/* FOR SMALLER SCREEN */
.nav__toggle {
    display: none;
}
.nav__container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.logo img {
    height: 56px;
    border-radius: 20px 0 0 20px;
}
.nav__links {
    display: flex;
    gap: 50px;
    font-size: 14px;
    font-weight: 500;
}
/* MEDIA QUERIES */
@media screen and (max-width: 800px) {
    nav {
        height: 50px;
       
    }
    .logo img {
        height: 46px;    
    }
    .nav__links {
        gap: 17px;
        font-size: 13px;
    }
    /* */
}
@media screen and (max-width: 650px) {
    nav {
        height: 60px;
    }
    .nav__toggle {
        margin-right: -147px;
    }
    .nav__toggle svg{            
        font-size: 22px;
    }
    .logo img {
        height: 57px;
        width: 57px;
    }
    .nav__toggle {
        display: inline-block;
        background: transparent;
        font-size: 18px;
        cursor: pointer;
    }
    
    .nav__links {
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
    }
    .nav__links li {
        height: 48px;
        width: 100%;
        box-shadow: -32px 32px 80px rgba(0, 0, 0, 0.6);
        animation: navAnimation 600ms ease forwards;
        transform: rotateX('90deg');
        opacity: 0;
        transform-origin: top;
    }
    .nav__links li:nth-child(2) {
        animation-delay: 200ms;
    }
    .nav__links li:nth-child(3) {
        animation-delay: 400ms;
    }
    .nav__links li:nth-child(4) {
        animation-delay: 600ms;
    }
    .nav__links li:nth-child(5) {
        animation-delay: 800ms;
    }
    .nav__links li:nth-child(6) {
        animation-delay: 1000ms;
    }
    .nav__links li:nth-child(7) {
        animation-delay: 1200ms;
    }
    @keyframes navAnimation {
        to {
            transform: rotateX(0);
            opacity: 1;
        }
    }
    .nav__links li {
        background-color: var(--sl-color-gray-2);  /*This will be overwritten by theme classes  */
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 16px 80px 16px 48px;
    }
    .show__nav {
        display: flex;      
    }
    .hide__nav {
        display: none;
    }
}
@media screen and (max-width: 390px){
    .logo img {
        height: 40px;
        width: 40px;
    }
    .nav__links {
        right: 10px;
    }
}


