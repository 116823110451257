.contact__holder{
    margin-top: 10px;
    display: flex;
   gap: 100px; 
    justify-content: center;
    align-items: center;
}

form{
    max-width: 500px;
    width: 100%;
    padding: 25px 25px 30px;
    border-radius: 8px;
    margin: 0 25px;
}

form h2 {
    text-align: center;
}
form .input-box{
    margin-top: 20px;
}
label{
    font-weight: 600;
}
.input-box .field{
    width: 100%;
    height: 50px;
    border: 2px solid var(--sl-color-gray-1);
    outline: none;
    border-radius: 6px;
    padding: 15px;
    font-size: 16px;
    color: var(--backdrop-color);
    margin-top: 8px;
}
.input-box .field-mess{
    width: 100%;
    height: 200px;
    font-size: 16px;
    resize: none;
}
form button{
    width: 100%;
    height: 55px;
    background: var(--sl-color-btn);
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: var(--sl-color-black);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.success-message {
    margin-top: 20px;
    padding: 10px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    text-align: center;
  }
.contact__options{
    display: flex;
    flex-direction: column;
    gap: 17px;
}  
.contact__options .option{
    border-radius: 6px;
    background: var(--sl-color-white);
    padding: 5px 50px; 
    text-align: center;
}
.contact__options .option .icon__container{
    display: grid;
    place-items: center;
width: 64px;
height: 64px;
font-size: 25px;

}
.contact__options .option .name{
    font-size: 16px;
    font-weight: 600;
    margin: 3px 0;
}
.contact__options .option .text__muted{
    font-size: 15px;
}
.contact__options .option .btn{
    display: inline-block;
    margin-top: 10px;
}
@media screen and (max-width: 800px){
    .contact__holder{
      flex-direction: column;
      align-items: stretch;
    }
    form{
        max-width: 100%;
        padding: 20px;
        margin: 0;
    }
}