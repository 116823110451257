.main__header {
    width: 100%;
   display: grid;
   place-items: center;
    margin-top: 48px;
}
.main__header-container{
    display: flex;
 justify-content: space-between;
 gap: 20px;
}
.main__header-left{
    width: 50%;
    margin: 30px 0 0 50px;
}
.main__header-right{
    width: 50%;
}
.main__header-left h1{
    width: 540px;
}
.main__header-left h1 span{
    color: var(--list-active-color);
}
.main__header-left p{
    width: 460px;
    margin: 10px 0 40px;
    font-size: 18px;
}
.main__header-left .btn {
    display: inline-block;
    text-align: center;
    background-color: var(--sl-color-btn);
    padding: 16px 32px;
    color: var(--sl-color-black);
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    transition: var(--transition);
}
.btn:hover{
background-color: var(--sl-color-btn-low);
color: var(--sl-color-gray-2);
}

.main__header-image img {
    width: 75%;
    margin: 0 auto;
    border-radius: 20px;

}

/* MEDIA QUERIES */
@media screen and (max-width: 1024px){
    .main__header-left{
        width: 66%;
        margin-top: 30px;
       
    }
    .main__header-left h1{
        width: 100%;
       
    }
    .main__header-image img{
        width:100%;
    }
}

@media screen and (max-width: 820px){
    .main__header-left{
        width: 51%;
        margin: 30px 0 0 0;
    }
    .main__header-left p {
        width: 358px;
    }
}
@media screen and (max-width: 780px) {
    .main__header {
        width: 100%;
        margin-top: 20px;
    }
   .main__header-container{
    max-width: 100%;
   }
   .main__header-left{
    padding: 10px;
    width: 50%;
    
   }
   .main__header-left h1 {
    width: 90%;
    font-size: 24px;
   }
   .main__header-left p{
    width: 280px;
    margin: 10px;
}
.main__header-left .btn {
    padding: 10px 20px;
    font-size: 12px;   
    height: 48px;
    width: 126px; 
}

.main__header-right img{
    width: 100%;
    margin-top: 48px;
 }
}

@media screen and (max-width: 650px) {
    
    .container {
        text-align: center;
        padding: 0;
    }
    .main__header-container {
        flex-direction: column;
        text-align: center;
        padding: 0;
    }
    .main__header-left{
        width: 100%;
        text-align: left;
    }
    .main__header-left h1 {
        width: 100%;
    }
    .main__header-left p {
        margin: 10px 0;
        width: 100%;
    }
    .main__header-right {
        width: 90%;
        margin: 0 auto;
    }
    
}
@media screen and (max-width: 390px) {
    .container {
        padding: 0 20px;
    }
}
