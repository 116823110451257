section {
    margin-top: 140px;
}
.section__head{
    width: 100%;
    text-align: center;
}
.section__head h2{
    color: var(--sl-color-gray-1);
    margin: 0 auto;
}
.section__head p{
    color: var(--sl-color-white);
    width: 50%;
    margin: 10px auto;
    padding: 10px 20px;
    text-align: center;
}
.company-overview-img{
    margin-left: auto;
    margin-right: auto;
    display: flex; 
    gap: 60px;
    justify-content: center;
}
.company-overview-img p{
    margin-top: 60px;
    width: 50%;
    font-size: 18px;
}
.company-overview-img img{
    border: 2px solid var(--sl-color-gray-3);
    width: 500px;
    height: 400px;
    border-radius: 20px;
}
.services__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}
.footer-logo{
    width: 50px;
    margin: auto;
}
.footer-logo img{
    width: 100%;
}
footer{
    background: var(--sl-color-gray-4);
    color: var(--sl-color-gray-2);
    width: 100%;
    margin-top: 100px;
    padding: 30px 50px 20px 50px;
    font-size: 16px;
}
.footer__container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 50px;
}
.footer__container article{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}
.footer__container article p{
    margin-top: 10px;
    font-size: 16px;
    color: var(--sl-color-black);
}
.footer-socials{
    display: flex;
    gap: 16px;
    align-items: center;
}
.footer-socials a{
    background: #14141486;
    color: var(--sl-color-white);
    padding: 8px 14px;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: var(--transition);
}
.footer-socials a:hover {
    background: var(--sl-color-gray-3);
}
.footer__copyright p {
    margin-top: 40px;
    border-top: 2px solid var(--sl-color-gray-2);
    text-align: center;
    padding: 18px 0;
}
@media screen and (max-width: 1024px) {
    .section__head p {
        width: 68%;
    }
    .company-overview-img {
        width: 90%;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
    }
    .company-overview-img img {
        width: 360px;
        height: 360px;
    }
    .footer__container {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 780px) {
    section {
        margin-top: 80px;
    }
    .section__head p {
        width: 80%;
        padding: 10px 20px;
    }
    .company-overview-img img {
        width: 350px;
        height: 350px;
        border-radius: 30px 0 0 10px;
    }
    .footer-logo {
        margin-left: 0;
    }
    footer {
        padding: 50px 20px 20px 20px;
    }
    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 30px;
    }
    .footer__container article {
        text-align: center;
    }
    .footer__copyright p {
        margin-top: 40px;
        padding: 10px 0;
    }
}
@media screen and (max-width: 500px) {
    .company-overview-img {
        width: 100%;
    }
    .footer-logo {
        width: 55px;
        margin-left: 0;
    }
    footer {
        padding: 30px 10px 10px 10px;
    }
    .footer__container {
        grid-template-columns: 1fr;
    }
    .footer__copyright p {
        margin-top: 20px;
        padding: 0;
        font-size: 14px;
    }
    .section__head h2 {
        width: 100%;
        text-align: start;
    }
    .section__head p {
        width: 90%;
        text-align: left;
    }
}
@media screen and (max-width: 400px) {
    .footer-logo {
        text-align: left;
        width: 50px;
    }
    .footer__container article p {
        width: 90%;
        font-size: 16px;
        color: var(--sl-color-black);
    }
    .footer__container article {
        width: fit-content;
    }
}
.calendar-section {
    margin-top: 20px;
    text-align: center;
}
